html {
  height: 100%;
}

.layout {
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
}
