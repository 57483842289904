/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/
$spacer-smaller: 22;
$spacer-small: 22;
$spacer-medium: 40;
$spacer-large: 40;

$grid: (
  small: (
    from: 0,
    to: 767,
    spacer: 20
  ),
  medium: (
    from: 768,
    to: 1199,
    spacer: 30
  ),
  large: (
    from: 1200,
    spacer: 40
  )
);

:root {
  --large-indent: calc((100% - ((var(--max-row-width) * 1px) - (#{$spacer-large} * 2px))) / 2);
  --medium-indent: calc(#{$spacer-medium} * 1px);
  --small-indent: calc(#{$spacer-small} * 1px);
  
  --max-row-width: 1280;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Arial", sans-serif;
  --h-font: "Arial", sans-serif;

  --t: transparent;

  --primary-color: #01c767;
  --alert-color: #b50000;
  --default-transition: all 0.4s;
  --primary-color-opacity: rgba(1, 199, 103, .85);
}
