.index-page {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &__container {
    width: 100vw;
    height: 100%;
    background: black;
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__text-wrapper{
    color: white;
  }

  &__container {

  }

  &__autoplay {
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 100;
    opacity: 0.3;
  }

  &__fullscreen {
    position: absolute;
    top: calc(50% + 40px);
    left: 10px;
    z-index: 100;
    opacity: 0.3;
  }

  &__autoplay-on {
    display: none;
  }

  &__container._autoplay {
    .index-page__autoplay-on {
      display: inline;
    }

    .index-page__autoplay-off {
      display: none;
    }
  }
}